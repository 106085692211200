'use client';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

import ToastError from '@/components/ToastError';
import { usePathname } from '@/navigation';
import { SECOND_IN_MS } from '@/utils/date-utils';

const TOAST_DISPLAY_DURATION_IN_MS = 5 * SECOND_IN_MS;

const ToastContainer = ({ msg }: { msg?: string }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return mounted
    ? ReactDOM.createPortal(<ToastError msg={msg} />, document.body)
    : null;
};

type ToastContextType = {
  showToast: (msg?: string) => void;
  showToastTemporary: (msg?: string) => void;
  hideToast: () => void;
};

const ToastContext = React.createContext<ToastContextType>({
  showToast: () => null,
  showToastTemporary: () => null,
  hideToast: () => null,
});

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [msg, setMsg] = useState<string>();
  const pathname = usePathname();

  useEffect(() => {
    hideToast();
  }, [pathname]);

  const showToast = useCallback(
    (msg?: string) => {
      setIsDisplayed(true);
      setMsg(msg);
    },
    [setIsDisplayed]
  );

  const showToastTemporary = useCallback(
    (msg?: string) => {
      showToast(msg);
      setTimeout(() => {
        hideToast();
      }, TOAST_DISPLAY_DURATION_IN_MS);
    },
    [setIsDisplayed]
  );

  const hideToast = useCallback(() => {
    setIsDisplayed(false);
    setMsg(undefined);
  }, [setIsDisplayed]);

  const contextValue = useMemo(
    () => ({
      showToast,
      hideToast,
      showToastTemporary,
    }),
    [showToast, hideToast]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {isDisplayed && <ToastContainer msg={msg} />}
      {children}
    </ToastContext.Provider>
  );
};

const useToastError = (): ToastContextType => {
  return useContext(ToastContext);
};

export { ToastContext, useToastError };
export default ToastProvider;
