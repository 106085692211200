'use client';

/* eslint-disable no-restricted-imports */
import { LinkProps as NextLinkProps } from 'next/link';
import { useSearchParams } from 'next/navigation';
import { ReactNode } from 'react';
import { forwardRef } from 'react';

import { Link as NextLink } from '@/navigation';
import { Locale, getPathnameFromHref } from '@/utils';
import { getAllParamsFromHref } from '@/utils/stringify-url';

import { EMPTY_URL } from './Header/constants';

import clsx from 'clsx';

type LinkProps = {
  className?: string;
  children?: ReactNode;
  noNextLink?: boolean;
} & NextLinkProps &
  React.ComponentPropsWithoutRef<'a'>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { className, children, noNextLink, locale, href, ...nextLinkProps } =
    props;

  const searchParams = useSearchParams();
  const allParams = getAllParamsFromHref(href);

  const updatedHref =
    !href || href === EMPTY_URL
      ? href
      : {
          pathname: getPathnameFromHref(href),
          hash: href.includes('#') ? href.split('#')[1] : undefined,
          query: {
            ...allParams,
            ...(searchParams?.has('supplier') && {
              supplier: searchParams?.get('supplier'),
            }),
          },
        };

  const mergeClassName = clsx('cursor-pointer', className);

  if (noNextLink) {
    return (
      <a href={href} ref={ref} className={mergeClassName} {...nextLinkProps}>
        {children}
      </a>
    );
  }

  return (
    <NextLink
      href={updatedHref}
      ref={ref}
      {...nextLinkProps}
      className={mergeClassName}
      locale={locale as Locale}
    >
      {children}
    </NextLink>
  );
});

Link.displayName = 'Link';
