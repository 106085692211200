import React from 'react';

import clsx from 'clsx';

export type InputProps = {
  name: string;
  hasError?: boolean;
  className?: string;
  'data-testid': string;
  suffix?: JSX.Element;
} & React.ComponentPropsWithoutRef<'input'>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { hasError, className, suffix, ...inputProps } = props;

    return (
      <div>
        <input
          ref={ref}
          type="text"
          className={clsx(
            'border rounded px-5 py-[11px] bg-white min-h-[48px] w-full',
            !hasError && 'border-grey-70',
            hasError && 'border-red-70',
            className
          )}
          {...inputProps}
        />
        {suffix != null && (
          <div className="absolute flex items-center p-3 bg-white top-2 bottom-2 right-1">
            {suffix}
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
