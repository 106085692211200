export enum formNames {
  LOCATION_APPLICATION = 'location_application',
  NEWSLETTER_FOOTER = 'newsletter_footer',
  MEETING_SPACE_REQUEST = 'meeting_space_request',
  MARKETING_LANDING_PAGE = 'marketing_landing_page',
  ENTERPRISE = 'enterprise_inquiry',
  BROKER = 'broker_request',
  LANDLORD = 'landlord_request',
  MARKETING = 'marketing_inquiry',
  GENERAL = 'general_inquiry',
  PR_AND_MEDIA = 'pr_media_inquiry',
}

export enum otherFormNames {
  CHECKOUT = 'checkout',
}

export const FormTypes = { ...formNames, ...otherFormNames };
export type FormTypes = typeof FormTypes;
