import React from 'react';

import ChevronIcon from '../../icons/ChevronIcon';
import { SelectOption } from './types';

import clsx from 'clsx';

export type SelectProps = {
  name: string;
  options: SelectOption[];
  hasError?: boolean;
  required?: boolean;
  'data-testid': string;
} & React.ComponentPropsWithoutRef<'select'>;

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props: SelectProps, ref) => {
    const {
      options,
      hasError,
      className,
      required,
      placeholder,
      defaultValue,
      ...selectProps
    } = props;

    return (
      <div className="relative flex items-center">
        <select
          ref={ref}
          className={clsx(
            className,
            'w-full border rounded px-5 py-[11px] focus:outline outline-2 outline-offset-2 appearance-none bg-white',
            'text-ellipsis pr-10  whitespace-nowrap overflow-hidden',
            !hasError && 'border-grey-70',
            hasError && 'border-red-70'
          )}
          {...(defaultValue ? { defaultValue } : {})}
          {...selectProps}
        >
          {!required && <option></option>}

          <option value="" disabled hidden>
            {placeholder}
          </option>

          {options.map((x) => (
            <option
              key={x.label}
              value={x.value}
              className="overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {x.label}
            </option>
          ))}
        </select>
        <ChevronIcon
          className={clsx(
            'absolute right-5',
            hasError && 'stroke-red-70',
            !hasError && 'stroke-teal-5'
          )}
        />
      </div>
    );
  }
);

Select.displayName = 'Select';
