'use client';

import { useState } from 'react';

import { useUserLocalization } from '@/hooks/useUserLocalization';
import { LocationPhases } from '@/lib/locations';
import { SimpleLocationDto } from '@/lib/locations/dto/simple-location.dto';
import { getNearestLocation } from '@/lib/locations/get-nearest-location';

type UseNearestLocationProps = {
  locale: string;
  disabled?: boolean;
  slugs?: string[];
  phases?: LocationPhases[];
  onChange?: (nearestLocation: SimpleLocationDto) => void;
};

function useNearestLocation({
  locale,
  slugs,
  phases,
  disabled,
  onChange,
}: UseNearestLocationProps) {
  const [nearestLocation, setNearestLocation] = useState<SimpleLocationDto>();
  const userLocalization = useUserLocalization({
    onUserLocalizationChange: (userGeoLoc) => {
      const {
        lnglat: { lat, lng },
      } = userGeoLoc;

      if (disabled) return;

      const fetchNearestLoc = async () => {
        const nearestLocation = await getNearestLocation({
          locale,
          latitude: lat,
          longitude: lng,
          slugs,
          phases,
        });
        setNearestLocation(nearestLocation);

        if (nearestLocation) {
          onChange?.(nearestLocation);
        }
      };

      fetchNearestLoc();
    },
  });

  return {
    userLocalization,
    nearestLocation,
  };
}

export default useNearestLocation;
