import { useTranslations } from 'next-intl';
import Image from 'next/image';

import alertIcon from '@/public/icons/alertIcon.svg';

export default function ToastError({ msg }: { msg?: string }) {
  const t = useTranslations('toast-error');

  return (
    <div
      data-testid="toast-error"
      className="fixed bottom-6 flex max-lg:flex-col items-center z-40 bg-[#FFF2F2] rounded-sm py-3 px-5 md:px-7 text-sm text-red-60 shadow left-1/2 -translate-x-2/4 w-[calc(100vw-40px)] md:w-auto"
    >
      <div className="flex items-center">
        <Image
          className="mr-3"
          src={alertIcon}
          alt={t('alt-icon')}
          aria-hidden="true"
        />
        {msg || t('description')}
      </div>

      {!msg && (
        <button
          className="ml-6 text-sm font-medium underline max-lg:mt-1 max-lg:self-end text-teal-50 hover:no-underline"
          data-testid="toast-error-refresh-link"
          onClick={() => window.location.reload()}
        >
          {t('refresh')}
        </button>
      )}
    </div>
  );
}
