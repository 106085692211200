import { useSearchParams } from 'next/navigation';

import { Supplier } from '@/utils/constants';
import { getAllParamsFromSearchParams } from '@/utils/stringify-url';

export function useSupplier() {
  const searchParams = useSearchParams();
  const allParams = getAllParamsFromSearchParams(searchParams);

  const supplier = allParams.supplier ? allParams.supplier : null;
  const isNuveenSupplier =
    supplier?.toLowerCase() === Supplier.NUVEEN.toLowerCase();

  return {
    isNuveenSupplier,
    supplier,
  };
}
