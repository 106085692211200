'use client';

import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { LOCALES } from './utils';

export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({
    locales: LOCALES,
    localePrefix: 'as-needed',
  });
