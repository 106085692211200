import { LocationDto, SimpleLocationSearchDto } from '@/lib/locations';
import { MeetingRoomDto } from '@/lib/meeting-rooms/dto/meeting-room.dto';
import { SimpleMeetingRoomDto } from '@/lib/meeting-rooms/dto/simple-meeting-room.dto';
import { StringBool } from '@/models/StringBool.enum';
import { LocationMapFeatureProps } from '@/utils/mapbox';

import { MeetingRoomMapFeatureProps } from './mapbox';

type GtmOptionsPageView = {
  pageType?: string;
};

type GtmOptionsLocation = {
  location_name?: string;
  location_city?: string;
  location_metro?: string;
  location_country?: string;
  location_id?: string;
};

type GtmOptionsCheckoutCustomize = {
  country?: string;
  metro?: string;
  location?: string;
  global_access?: StringBool;
  location_id?: string;
  promo_code?: string;
};

type GtmOptionsMeetingRoom = {
  location_name?: string;
  meeting_room_name?: string;
};

export type GtmOptions = GtmOptionsPageView &
  GtmOptionsLocation &
  GtmOptionsMeetingRoom &
  GtmOptionsCheckoutCustomize;

export const isDataLayerLoaded = () => {
  return typeof window !== 'undefined' && window.dataLayer;
};

export const pushEvent = (event: string, options = {}) => {
  if (!window.dataLayer) return;
  window.dataLayer.push({ event, ...options });
};

export const pageview = ({
  url,
  pageType,
  options = {},
}: {
  url: string;
  pageType: string;
  options: GtmOptions;
}) => {
  pushEvent('pageview', { url, pageType, ...options });
};

export const pushECommerceEvent = (
  event: string,
  options: Record<string, unknown>
) => {
  if (!window.dataLayer) return;
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  pushEvent(event, { ecommerce: options });
};

const AFFILIATION = 'Online purchase';
const PRODUCT_CATEGORY = 'On demand';
const MEETING_ROOM_PRODUCT = 'Meeting room';
const COWORKING_ITEM_NAME = 'Coworking day pass';
const DAY_PASS_PRODUCT = 'Day pass';
const INDUSTRIOUS_BRAND_NAME = 'Industrious';

export const mapMeetingRoomToItemEcommerce = (meetingRoom: MeetingRoomDto) => {
  return {
    item_name: `${meetingRoom.locationName} - ${meetingRoom.name}`,
    item_id: meetingRoom.id,
    affiliation: AFFILIATION,
    item_brand: meetingRoom.brandName,
    item_category: meetingRoom.locationName,
    item_category2: PRODUCT_CATEGORY,
    item_category3: MEETING_ROOM_PRODUCT,
    ...(meetingRoom.locationTier != null && {
      item_category4: `Tier ${meetingRoom.locationTier}`,
    }),
    item_variant: meetingRoom.seats,
    location_id: meetingRoom.locationId,
  };
};

export const mapSimpleMeetingRoomToItemEcommerce = (
  meetingRoom: SimpleMeetingRoomDto | MeetingRoomMapFeatureProps
) => {
  return {
    item_name: `${meetingRoom.locationName} - ${meetingRoom.name}`,
    affiliation: AFFILIATION,
    item_category: meetingRoom.locationName,
    item_category2: PRODUCT_CATEGORY,
    item_category3: MEETING_ROOM_PRODUCT,
    ...(meetingRoom.locationTier != null && {
      item_category4: `Tier ${meetingRoom.locationTier}`,
    }),
    item_variant: meetingRoom.seats,
  };
};

export const mapCoworkingToItemEcommerce = (
  location: SimpleLocationSearchDto | LocationMapFeatureProps | LocationDto
) => {
  return {
    item_name: COWORKING_ITEM_NAME,
    affiliation: AFFILIATION,
    item_brand: INDUSTRIOUS_BRAND_NAME,
    item_category: location.name,
    item_category2: PRODUCT_CATEGORY,
    item_category3: DAY_PASS_PRODUCT,
    location_id: location.id,
  };
};
