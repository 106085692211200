import { LatLng } from '@/models/LatLng';
import { DEFAULT_COUNTRY, EUROPE_COUNTRIES_CODES } from '@/utils/constants';
import { tryFetchToJson } from '@/utils/http-request';

const DEFAULT_LAT_NY = 40.6976637;
const DEFAULT_LNG_NY = -74.1197637;

export function getDefaultLatLng(): LatLng {
  return { lng: DEFAULT_LNG_NY, lat: DEFAULT_LAT_NY };
}

export async function getLocalizationFromIPAddress(
  callbackFn: (params: { country: string; lnglat: LatLng }) => void
) {
  const ipinfosUser = await tryFetchToJson<{
    loc: string;
    country: string;
  }>({
    fetchParams: { input: '/api/user-geolocation' },
    onFunctionalError: () => {
      callbackFn({ country: DEFAULT_COUNTRY, lnglat: getDefaultLatLng() });
    },
    onTechnicalError: () => {
      callbackFn({ country: DEFAULT_COUNTRY, lnglat: getDefaultLatLng() });
    },
  });

  if (ipinfosUser == null) return;

  return callbackFn({
    country: ipinfosUser.country,
    lnglat: getLatLngFromString(ipinfosUser.loc),
  });
}

export function getLatLngFromString(coords: string): LatLng {
  const [lat, lng] = coords.split(',').map((x) => +x);

  return {
    lat,
    lng,
  };
}

export function isEuropeCountry(country: string) {
  return EUROPE_COUNTRIES_CODES.includes(country.toLowerCase());
}
