'use client';

import { useEffect, useState } from 'react';

import {
  getDefaultLatLng,
  getLocalizationFromIPAddress,
} from '@/lib/geolocation';
import { LatLng } from '@/models/LatLng';
import { DEFAULT_COUNTRY } from '@/utils/constants';

const USER_GEOLOC_STORAGE_KEY = 'user-geoloc';

export type UserGeoLoc = {
  country: string;
  lnglat: LatLng;
};

export function useUserLocalization({
  onUserLocalizationChange,
}: {
  onUserLocalizationChange?: (userGeoLoc: UserGeoLoc) => void;
} = {}) {
  const [userLocalization, setUserLocalization] = useState<UserGeoLoc>({
    country: DEFAULT_COUNTRY,
    lnglat: getDefaultLatLng(),
  });
  const [isLocalizationFromDevice, setIsLocalizationFromDevice] =
    useState(false);
  const [isDeviceLocalizationDenied, setIsDeviceLocalizationDenied] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userGeoLocStorage = window.sessionStorage.getItem(
        USER_GEOLOC_STORAGE_KEY
      );

      if (userGeoLocStorage != null) {
        const userGeoLoc = JSON.parse(userGeoLocStorage);

        onUserLocalizationChange?.(userGeoLoc);

        setUserLocalization(userGeoLoc);
        setIsLoading(false);
        return;
      }

      const getGeoloc = async () => {
        await getLocalizationFromIPAddress((result) => {
          onUserLocalizationChange?.(result);

          setUserLocalization(result);
          window.sessionStorage.setItem(
            USER_GEOLOC_STORAGE_KEY,
            JSON.stringify(result)
          );
        });
        setIsLoading(false);
      };

      getGeoloc();
    }
  }, []);

  const getDeviceLocalization = () => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const nextUserLocalization = {
            country: userLocalization.country,
            lnglat: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          };

          onUserLocalizationChange?.(nextUserLocalization);

          setUserLocalization(nextUserLocalization);
          setIsLocalizationFromDevice(true);
          setIsLoading(false);
        },
        () => {
          setIsDeviceLocalizationDenied(true);
          setIsLoading(false);
        }
      );
    } else {
      setIsDeviceLocalizationDenied(true);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isLocalizationFromDevice,
    isDeviceLocalizationDenied,
    country: userLocalization.country,
    lnglat: userLocalization.lnglat,
    getDeviceLocalization,
  };
}
