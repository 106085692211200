import { IconProps } from './types';

type ChevronIconProps = {
  className?: string;
  isBold?: boolean;
  strokeOpacity?: number;
} & IconProps;

export default function ChevronIcon({
  isBold,
  strokeOpacity,
  ...otherProps
}: ChevronIconProps) {
  return (
    <svg
      width={12}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M1 .95 5.95 5.9 10.9.95"
        strokeWidth={isBold ? '2' : '1'}
        strokeOpacity={strokeOpacity}
      />
    </svg>
  );
}
