export type MenuItem = {
  key: string;
  label?: string;
  url?: string;
  locale?: string;
  isExternal?: boolean;
  subMenu?: MenuItem[];
  isSelected?: boolean;
  showInDesktop?: boolean;
  isAction?: boolean;
  hasNewTag?: boolean;
  widthSubMenu?: number;
};

export enum MenuKey {
  Members = 'members',
  Workspaces = 'workspaces',
  Locations = 'locations',
  Partnerships = 'partnerships',
  Ressources = 'ressources',
  User = 'user',
  Account = 'account',
}

export const EMPTY_URL = '#';

export const MOBILE_NUVEEN_MENU: MenuItem[] = [
  {
    key: MenuKey.Members,
    url: '/account',
    subMenu: [
      {
        key: 'reservations',
        url: '/account/reservations',
      },
      {
        key: 'logout',
        isAction: true,
      },
    ],
    showInDesktop: false,
  },
];

export const MENU: MenuItem[] = [
  {
    key: MenuKey.Members,
    url: '/account',
    subMenu: [
      {
        key: 'reservations',
        url: '/account/reservations',
      },
      {
        key: 'payment-methods',
        url: '/account/payment-methods',
      },
      {
        key: 'account',
        url: '/account/account-settings',
      },
      {
        key: 'logout',
        isAction: true,
      },
    ],
    showInDesktop: false,
  },
  {
    key: 'workspaces',
    widthSubMenu: 220,
    subMenu: [
      {
        key: 'solutions',
        url: '/solutions',
      },
      {
        key: 'book-meeting-room',
        url: '/meeting-rooms',
      },
    ],
  },
  {
    key: 'locations',
    url: '/locations',
  },
  {
    key: 'partnerships',
    subMenu: [
      {
        key: 'enterprise',
        url: '/enterprise',
      },
      {
        key: 'landlords',
        url: '/landlords',
      },
      {
        key: 'brokers',
        url: '/brokers',
      },
    ],
  },
  {
    key: 'ressources',
    subMenu: [
      {
        key: 'blog',
        url: '/blog',
      },
      {
        key: 'faqs',
        url: '/about-us#faqs',
      },
    ],
  },
];

export const USER_AUTH_MENU_ITEMS: MenuItem = {
  key: 'members',
  subMenu: [
    {
      key: 'login',
    },
    {
      key: 'signup',
    },
  ],
};

export const NUVEEN_SUPPLIER_MENU: MenuItem[] = [
  {
    key: 'reservations',
    url: '/account/reservations',
  },
];

export const SUPPLIER_USER_AUTH_MENU_ITEMS: MenuItem = {
  key: 'supplier',
  subMenu: [
    {
      key: 'login',
    },
    {
      key: 'signup',
    },
  ],
};

export const SUPPLIER_ACCOUNT_MENU_ITEMS: MenuItem = {
  key: MenuKey.Account,
  subMenu: [
    {
      key: 'account',
      url: '/account/account-settings',
    },
    {
      key: 'logout',
    },
  ],
};

export const ACCOUNT_MENU_ITEMS: MenuItem = {
  key: MenuKey.Account,
  subMenu: [
    {
      key: 'reservations',
      url: '/account/reservations',
    },
    {
      key: 'payment-methods',
      url: '/account/payment-methods',
    },
    {
      key: 'account',
      url: '/account/account-settings',
    },
    {
      key: 'logout',
    },
  ],
};
